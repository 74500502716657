<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :tela="permission"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <dashboard ref="dashboard" @search="handleGetAgendamentos" />
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Gerenciamento de Serviços">
          <v-dialog v-model="showDocumentos" width="700px">
            <v-card>
              <documentos />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovantes" width="700px">
            <v-card>
              <comprovantes />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovante" fullscreen>
            <v-card>
              <comprovante />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovantePesagem" fullscreen>
            <v-card>
              <comprovante-pesagem />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovanteAgendamento" fullscreen>
            <v-card>
              <comprovante-agendamento />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showOrdemDeCarga" fullscreen>
            <v-card>
              <ordem-de-carga />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showComprovanteLaudo">
            <v-card>
              <comprovante-laudo :itensLaudo="this.itensLaudo" />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showComprovanteServicoAdicional">
            <v-card>
              <comprovante-servico-adicional />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showLogsIntegracoes">
            <v-card>
              <LogsIntegracoes />
            </v-card>
          </v-dialog>

          <template slot="right">
            <router-link
              v-if="canCreate"
              :to="{
                name: 'NovoAgendamento',
                params: {},
              }"
            >
              <v-btn color="primary" dark class="mb-2">
                Novo Agendamento
              </v-btn>
            </router-link>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canList"
                  color="primary"
                  dark
                  class="ml-2 mb-2"
                  v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <indicadores-gerencimento-servico
                :headers="headers"
                @save="saveHeaders"
                @close="close"
              />
            </v-dialog>
            <v-btn
              v-if="canList"
              color="primary"
              dark
              class="ml-2 mb-2"
              @click="handleGetAgendamentos"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>

          <template>
            <v-col>
              <gerenciamento-servico-list-filtro
                @search="handleGetAgendamentos"
              />
            </v-col>
          </template>

          <listar-gerenciamento-servico
            :search="search"
            :headers="headersComputed"
            ref="listagem"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarGerenciamentoServico from '@/components/patio/GerenciamentoServico/ListarGerenciamentoServico'
import GerenciamentoServicoListFiltro from '@/components/patio/GerenciamentoServico/GerenciamentoServicoListFiltro.vue'
import IndicadoresGerencimentoServico from '@/components/patio/GerenciamentoServico/IndicadoresGerencimentoServico.vue'
import Comprovante from '@/components/patio/NovoAgendamento/comprovante/Componente.vue'
import ComprovantePesagem from '@/components/patio/NovoAgendamento/comprovantePesagem/Componente.vue'
import Comprovantes from '@/components/patio/NovoAgendamento/comprovantes/Componente.vue'
import ComprovanteServicoAdicional from '@/components/patio/NovoAgendamento/comprovanteServicoAdicional/Componente.vue'
import ComprovanteAgendamento from '@/components/patio/NovoAgendamento/comprovanteAgendamento/Componente.vue'
import OrdemDeCarga from '@/components/patio/NovoAgendamento/OrdemDeCarga/Componente.vue'
import ComprovanteLaudo from '@/components/patio/NovoAgendamento/comprovanteLaudo/Componente.vue'
import Documentos from '@/components/patio/NovoAgendamento/documentos/Componente.vue'
import LogsIntegracoes from '@/components/patio/NovoAgendamento/logsIntegracoes/Componente.vue'

import Dashboard from '@/components/patio/GerenciamentoServico/Dashboard.vue'

import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

const HEADERS_GERENCIAMENTO_SERVICO_LOCALSTORAGE = 'headersGerenciamentoServico'

export default {
  provide() {
    return {
      visualizarDocumentos: this.visualizarDocumentos,
      visualizarComprovante: this.visualizarComprovante,
      visualizarComprovantePesagem: this.visualizarComprovantePesagem,
      visualizarComprovantes: this.visualizarComprovantes,
      visualizarComprovanteAgendamento: this.visualizarComprovanteAgendamento,
      visualizarOrdemDeCarga: this.visualizarOrdemDeCarga,
      visualizarComprovanteServicoAdicional: this
        .visualizarComprovanteServicoAdicional,
      visualizarComprovanteLaudo: this.visualizarComprovanteLaudo,
      visualizarLogsIntegracoes: this.visualizarLogsIntegracoes,
      fecharDocumentos: this.fecharDocumentos,
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
      fecharOrdemDeCarga: this.fecharOrdemDeCarga,
      fecharComprovanteTriagem: this.fecharComprovanteTriagem,
      fecharComprovantePesagem: this.fecharComprovantePesagem,
      fecharComprovanteServicoAdicional: this.fecharComprovanteServicoAdicional,
      fecharComprovanteServicos: this.fecharComprovanteServicos,
      fecharLogsIntegracoes: this.fecharLogsIntegracoes,
    }
  },
  components: {
    ListarGerenciamentoServico,
    IndicadoresGerencimentoServico,
    GerenciamentoServicoListFiltro,
    Dashboard,
    Comprovante,
    ComprovantePesagem,
    Comprovantes,
    ComprovanteAgendamento,
    OrdemDeCarga,
    ComprovanteLaudo,
    ComprovanteServicoAdicional,
    Documentos,
    LogsIntegracoes,
  },
  data() {
    return {
      dialog: false,
      showForm: false,
      showDocumentos: false,
      showComprovante: false,
      showComprovantePesagem: false,
      showComprovanteLaudo: false,
      showComprovanteAgendamento: false,
      showOrdemDeCarga: false,
      showComprovanteServicoAdicional: false,
      showComprovantes: false,
      showLogsIntegracoes: false,
      search: '',
      itensLaudo: [],
      page: {
        title: 'Gerenciamento de Serviços',
      },
      headers: [
        {
          habilited: false,
          block: false,
          text: 'Etapas',
          value: 'etapas',
          sortable: false,
          display: false,
        },
        {
          habilited: true,
          block: true,
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
        { habilited: true, block: true, text: 'Status', value: 'status' },
        {
          habilited: true,
          block: false,
          text: 'Veículo',
          value: 'veiculo',
        },
        {
          habilited: true,
          block: false,
          text: 'Carreta 1',
          value: 'placa_carreta_1',
        },
        {
          habilited: true,
          block: false,
          text: 'Carreta 2',
          value: 'placa_carreta_2',
        },
        {
          habilited: true,
          block: false,
          text: 'Senha',
          value: 'senhas',
        },
        {
          habilited: true,
          block: false,
          text: 'Tipo Operação',
          value: 'tipo_operacao',
        },
        {
          habilited: false,
          block: false,
          text: 'Data da Cota',
          value: 'data_cota',
        },
        {
          habilited: false,
          block: false,
          text: 'Data da Janela',
          value: 'data_janela',
        },
        {
          habilited: false,
          block: false,
          text: 'Data do Pedido',
          value: 'data_pedido',
        },
        {
          habilited: false,
          block: false,
          text: 'Agendado Em',
          value: 'agendado_em',
        },
        {
          habilited: true,
          block: false,
          text: 'Triado Em',
          value: 'triado_em',
        },
        {
          habilited: true,
          block: false,
          text: 'Agendado por',
          value: 'agendado_por',
        },
        {
          habilited: true,
          block: false,
          text: 'Tempo em pátio',
          value: 'tempoEmPatio',
        },
        {
          habilited: false,
          block: false,
          text: 'Fornecedor',
          value: 'fornecedor',
        },
        {
          habilited: true,
          block: false,
          text: 'SubProduto',
          value: 'pedidos_agendados.sub_produto',
        },
        {
          habilited: true,
          block: false,
          text: 'Quantidade (KG)',
          value: 'pedidos_agendados.quantidade',
        },
        {
          habilited: false,
          block: false,
          text: 'Peso TARA (KG)',
          value: 'pesagem_tara.peso',
        },
        {
          habilited: false,
          block: false,
          text: 'Data TARA',
          value: 'pesagem_tara.data',
        },
        {
          habilited: false,
          block: false,
          text: 'Peso BRUTO (KG)',
          value: 'pesagem_bruto.peso',
        },
        {
          habilited: false,
          block: false,
          text: 'Data BRUTO',
          value: 'pesagem_bruto.data',
        },
        {
          habilited: false,
          block: false,
          text: 'Peso Liquido (KG)',
          value: 'peso_liquido',
        },
        {
          habilited: false,
          block: false,
          text: 'Quebra (KG)',
          value: 'peso_quebra',
        },
        {
          habilited: false,
          block: false,
          text: 'Quebra (%)',
          value: 'perc_quebra',
        },
        {
          habilited: true,
          block: false,
          text: 'Embalagem',
          value: 'pedidos_agendados.embalagem',
        },
        {
          habilited: false,
          block: false,
          text: 'Gate',
          value: 'gate',
        },
        {
          habilited: true,
          block: false,
          text: 'Cliente',
          value: 'cliente',
        },
        {
          habilited: false,
          block: false,
          text: 'Cliente CNPJ',
          value: 'cliente_cnpj',
        },
        {
          habilited: true,
          block: false,
          text: 'Terminal',
          value: 'terminal',
        },
        {
          habilited: false,
          block: false,
          text: 'Terminal CNPJ',
          value: 'terminal_cnpj',
        },
        {
          habilited: true,
          block: false,
          text: 'Terminal Destino',
          value: 'terminal_destino',
        },
        {
          habilited: true,
          block: false,
          text: 'Região',
          value: 'regiao',
        },
        {
          habilited: true,
          block: false,
          text: 'Eixos',
          value: 'eixos',
        },
        {
          habilited: false,
          block: false,
          text: 'Motorista',
          value: 'motorista',
        },
        {
          habilited: false,
          block: false,
          text: 'N° Tel',
          value: 'motorista_tel',
        },
        {
          habilited: false,
          block: false,
          text: 'N° Doc. Motorista',
          value: 'motorista_doc',
        },
        {
          habilited: false,
          block: false,
          text: 'Proprietário',
          value: 'proprietario',
        },
        {
          habilited: false,
          block: false,
          text: 'Entrada triagem',
          value: 'auto_ent_triagem',
        },
        {
          habilited: false,
          block: false,
          text: 'Transportadora',
          value: 'transportadora',
        },
        {
          habilited: false,
          block: false,
          text: 'Transportadora CNPJ',
          value: 'transportadora_cnpj',
        },
        {
          habilited: false,
          block: false,
          text: 'Nº Pedido',
          value: 'pedidos_agendados.numero_pedido',
        },
        {
          habilited: false,
          block: false,
          text: 'Produto',
          value: 'pedidos_agendados.produto',
        },
        {
          habilited: false,
          block: false,
          text: 'Návio',
          value: 'navio',
        },
        {
          habilited: false,
          block: false,
          text: 'Tipo Frete',
          value: 'tipo_frete',
        },
        { habilited: false, block: false, text: 'DI', value: 'di' },
        {
          habilited: false,
          block: false,
          text: 'Nº Nota fiscal',
          value: 'nota_fiscal',
        },
        { habilited: false, block: false, text: 'Píer', value: 'pier' },
        {
          habilited: false,
          block: false,
          text: 'ID Bunge',
          value: 'classifWebId',
        },
        {
          habilited: false,
          block: false,
          text: 'Status Integração',
          value: 'statusMessage',
        },
        {
          habilited: false,
          block: false,
          text: 'Status Porto Net',
          value: 'statusMessageIntegracao',
        },
        {
          habilited: false,
          block: false,
          text: 'ID Externo',
          value: 'idAgendamentoExternoIntegracao',
        },
        {
          habilited: false,
          block: false,
          text: 'Vaga',
          value: 'vaga.descricao',
        },
        {
          habilited: false,
          block: false,
          text: 'Setor',
          value: 'vaga.setor.descricao',
        },
        {
          habilited: false,
          block: false,
          text: 'Vaga bonificado',
          value: 'vaga.setor.bonificada',
        },
        {
          habilited: false,
          block: false,
          text: 'Observação',
          value: 'observacao',
        },
        {
          habilited: false,
          block: false,
          text: 'Umidade',
          value: 'umidade',
        },
        {
          habilited: false,
          block: false,
          text: 'Remetente',
          value: 'remetente',
        },
        {
          habilited: false,
          block: false,
          text: 'Destinatário',
          value: 'destinatario',
        },
        {
          habilited: false,
          block: false,
          text: 'PUBLIC ID AGENDAMENTO',
          value: 'public_id',
        },
        {
          habilited: false,
          block: false,
          text: 'PUBLIC ID VEICULO',
          value: 'veiculo__public_id',
        },
        {
          habilited: false,
          block: false,
          text: 'PUBLIC ID MOTORISTA',
          value: 'motorista__public_id',
        },
      ],
      breadcrumbs: [
        {
          text: 'Pátio',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Gerenciamento de Serviços',
          disabled: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('agendamentos', ['agendamento']),

    headersComputed() {
      return this.headers.filter((header) => !!header.habilited)
    },

    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  // watch: {
  //   headersComputed() {
  //     this.saveHeaders(this.headers)
  //   },
  // },

  async created() {
    this.setExtraHeaders()
    this.setFiltrosGerenciamentoServicos()
  },

  methods: {
    ...mapActions('template', ['errorMessage']),
    ...mapActions('patio', [
      'getAgendamentoGerenciamentoServico',
      'getItensLaudoCheckList',
    ]),
    setExtraHeaders() {
      const extraHeaders = []
      if (this.empresaAtual.extra_fields) {
        for (const by in this.empresaAtual.extra_fields) {
          if (this.empresaAtual.extra_fields[by].agendamento) {
            Object.entries(
              this.empresaAtual.extra_fields[by].agendamento
            ).forEach((values) => {
              const _extra_header = {
                habilited: false,
                block: false,
                text: `${by} - ${values[1]}`,
                value: `extra.${by}.${values[0]}`,
              }
              extraHeaders.push(_extra_header)
            })
          }
        }
      }
      this.headers = this.headers.concat(extraHeaders)
    },
    saveHeaders(headers) {
      if (!headers) return
      localStorage.setItem(
        HEADERS_GERENCIAMENTO_SERVICO_LOCALSTORAGE,
        JSON.stringify(headers)
      )
    },
    visualizarDocumentos() {
      this.showDocumentos = true
    },
    visualizarComprovantes() {
      this.showComprovantes = true
    },
    visualizarComprovante() {
      this.showComprovante = true
    },
    visualizarComprovantePesagem() {
      this.showComprovantePesagem = true
    },
    visualizarComprovanteServicoAdicional() {
      this.showComprovanteServicoAdicional = true
    },

    visualizarComprovanteAgendamento() {
      this.showComprovanteAgendamento = true
    },

    visualizarOrdemDeCarga() {
      this.showOrdemDeCarga = true
    },

    visualizarComprovanteLaudo() {
      this.showComprovanteLaudo = true
      this.getLaudoItensCheckList()
    },

    visualizarLogsIntegracoes() {
      this.showLogsIntegracoes = true
    },

    fecharDocumentos() {
      this.showDocumentos = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },

    fecharLogsIntegracoes() {
      this.showLogsIntegracoes = false
    },

    fecharComprovanteAgendamento() {
      this.showComprovanteAgendamento = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },

    fecharOrdemDeCarga() {
      this.showOrdemDeCarga = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },
    fecharComprovantePesagem() {
      this.showComprovantePesagem = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },
    fecharComprovanteTriagem() {
      this.showComprovante = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },
    fecharComprovanteServicos() {
      this.showComprovantes = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },

    fecharComprovanteServicoAdicional() {
      this.showComprovanteServicoAdicional = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },

    async getAgendamento(agendamento) {
      return await this.getAgendamentoGerenciamentoServico(agendamento)
    },

    async getLaudoItensCheckList() {
      try {
        this.itensLaudo = []
        var itensLaudoCheckList = await this.getItensLaudoCheckList(
          this.agendamento
        )
        for (var i = 0; i < itensLaudoCheckList.length; i++) {
          var checkTrue = ''
          var checkFalse = ''
          var obj = {
            descricao: '',
            sim: '',
            nao: '',
            observacao: '',
          }

          if (itensLaudoCheckList[i].selectedItemKey == 1) {
            checkTrue = 'X'
          } else if (itensLaudoCheckList[i].selectedItemKey == 2) {
            checkFalse = 'X'
          }
          obj.descricao = itensLaudoCheckList[i].description
          obj.sim = checkTrue
          obj.nao = checkFalse
          obj.observacao = itensLaudoCheckList[i].observation
          this.itensLaudo.push(obj)
        }
        return this.itensLaudo
      } catch (e) {
        if (e.response) {
          this.errorMessage(e.response.data)
          return
        }
        if (e.message) {
          this.errorMessage(e.message)
          return
        }
        this.errorMessage(e)
      }
    },

    async handleGetAgendamentos() {
      const { listagem } = this.$refs
      await listagem.handleGetAgendamentos()
      this.$refs['dashboard'].updateDashboard()
    },

    setFiltrosGerenciamentoServicos() {
      const headersSaved = localStorage.getItem(
        HEADERS_GERENCIAMENTO_SERVICO_LOCALSTORAGE
      )

      const headersSavedParsed = JSON.parse(headersSaved)
      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const headersMap = this.headers.map((item) => item.value)
        // Pega apenas os itens que existem no headers (itens antigos são desconsiderados)
        const _headersSavedParsed = headersSavedParsed.filter((item) =>
          headersMap.includes(item.value)
        )
        const _headersSavedParsedMap = _headersSavedParsed.map(
          (item) => item.value
        )
        const headers_saved_map_habilited = _headersSavedParsed
          .filter((item) => item.habilited)
          .map((item) => item.value)
        const headers_saved_map_not_habilited = _headersSavedParsed
          .filter((item) => !item.habilited)
          .map((item) => item.value)
        // headersSavedParsed
        const newItems = this.headers
          .map((item, index) => {
            return [index, item]
          })
          .filter((item) => !_headersSavedParsedMap.includes(item[1].value))

        this.headers = _headersSavedParsed.map((item) => {
          let habilited = false
          if (headers_saved_map_habilited.includes(item.value)) {
            habilited = true
          } else {
            if (headers_saved_map_not_habilited.includes(item.value)) {
              habilited = false
            } else {
              // Para os novos
              habilited = item.habilited
            }
          }
          return {
            ...item,
            habilited,
          }
        })
        // Adiciona os novos itens na ordem que eles deveriam estar
        newItems.forEach((element) => {
          const [index, value] = element
          this.headers.splice(index, 0, value)
        })
        this.saveHeaders(this.headers)
      } else {
        this.saveHeaders(this.headers)
      }
    },

    close() {
      this.saveHeaders(this.headers)
      this.dialog = false
    },
  },
}
</script>
