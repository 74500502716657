<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn type="button" color="primary" @click="fecharComprovantePesagem">
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-4 left-align no-padding">
        <strong>{{ agendamento.fornecedor.business_name }} OPERAÇÃO</strong>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Ticket Pesagem:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>143038</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Veiculo/Cavalo:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{ agendamento.veiculo.placa_cavalo || defaultValue }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Motorista:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{ agendamento.motorista.user.name || defaultValue }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Navio:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>0</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Tipo Operação:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{ agendamento.tipo_operacao.descricao }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Id Externo:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{ agendamento.idAgendamentoExternoIntegracao }}</span>
      </v-col>
    </v-row>

    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>DI:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>0</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Transportadora:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{
          agendamento.transportadora.business_name || defaultValue
        }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Cliente:</strong>
      </v-col>
      <v-col class="col-4 left-align no-padding">
        <span>{{ agendamento.cliente.business_name || defaultValue }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Destinatário:</strong>
      </v-col>
      <v-col class="col-4 left-align no-padding">
        <span>{{ agendamento.destinatario.business_name }}</span>
      </v-col>
    </v-row>

    <v-row style="margin-top: 0px">
      <v-col style="margin-top: 0px">
        <div
          v-for="(pedido, index) in agendamento.pedidos_agendados"
          :key="index"
        >
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>SubProduto:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{ pedido.pedido.subProduto.descricao }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Quantidade:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{ converteKg(pedido.quantidade_agendada) }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Nota Fiscal:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{ pedido.numero_nfe }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Numero Pedido:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{ pedido.numero_pedido }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Peso TARA:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{
                agendamento.pesagem_tara != null
                  ? agendamento.pesagem_tara.peso
                  : '-'
              }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Peso BRUTO:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{
                agendamento.pesagem_bruto != null
                  ? agendamento.pesagem_bruto.peso
                  : '-'
              }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Peso Liquido:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{ agendamento.peso_liquido || '-' }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Quebra / Sobra (KG):</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{ agendamento.peso_liquido - converteKg(pedido.quantidade_agendada) }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Quebra / Sobra (%):</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{ ((agendamento.peso_liquido / converteKg(pedido.quantidade_agendada)) * 100).toFixed(2) }}%</span>
            </v-col>
          </v-row>

          

          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Ent. Data e Hora:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{
                agendamento.pesagem_tara != null
                  ? agendamento.pesagem_tara.data
                  : '-'
              }}</span>
            </v-col>
          </v-row>
          <v-row class="no-margin">
            <v-col class="col-2 left-align no-padding">
              <strong>Sad. Data e Hora:</strong>
            </v-col>
            <v-col class="col-2 left-align no-padding">
              <span>{{
                agendamento.pesagem_bruto != null
                  ? agendamento.pesagem_bruto.data
                  : '-'
              }}</span>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Observação:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{ agendamento.observacao }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Operador:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{ agendamento.observacao }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Responsável:</strong>
      </v-col>
      <v-col class="col-2 left-align no-padding">
        <span>{{ agendamento.observacao }}</span>
      </v-col>
    </v-row>
    <v-row class="no-margin">
      <v-col class="col-2 left-align no-padding">
        <strong>Ass:____________________</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal, date2br } from '@/mixins/convertion.js'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'
import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'
import { Pesagem } from '@/utils/pesagem'

export default {
  name: 'Comprovante',
  inject: ['fecharComprovantePesagem'],
  mixins: [
    toReal,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  data,
  computed,
  filters,
  async created() {},
  methods,
  Pesagem,
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

.col-comprovante {
  padding: 1px;
}

/* Alinhamento à esquerda */
.left-align {
  text-align: left;
}

/* Remove margens e preenchimentos entre colunas */
.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

/* Estilo do título */
.title-comprovante {
  font-size: 24px; /* Aumenta o tamanho do título */
  margin-bottom: 10px; /* Adiciona espaço abaixo do título */
}

/* configuração para impressora térmica */
@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }

  .v-card {
    height: 100%;
    width: 100%;
  }

  .v-data-table th,
  .v-data-table td {
    font-size: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .aviso {
    font-size: 10px;
  }

  @page {
    margin: 0;
  }
}
</style>
