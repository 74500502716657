import { FormaPagamento } from '@/utils/faturamento'
import { Pesagem } from '@/utils/pesagem'

export function data() {
  return {
    tipos: {
      ENTRADA: { carga: Pesagem.TARA, descarga: Pesagem.BRUTO },
      SAIDA: { carga: Pesagem.BRUTO, descarga: Pesagem.TARA },
    },

    FormaPagamento,
    defaultValue: 'Informação não encontrada',

    senha: null,
    exibeClassificacao: false,
    classificacoes: [],

    headersPedidos: [
      { text: 'N° Pedido', value: 'pedido.numero_pedido' },
      { text: 'SubProduto', value: 'pedido.subProduto.descricao' },
      { text: 'Quantidade', value: 'quantidade_agendada' },
      { text: 'N° NFE', value: 'numero_nfe' },
      { text: 'Chave de acesso', value: 'chave_acesso' },
      { text: 'Embalagem', value: 'pedido.embalagem.descricao' },
    ],
    headers: [
      { text: 'Servico', value: 'servico.descricao' },
      { text: 'Valor', value: 'valorTotal' },
      { text: 'Forma Pagamento', value: 'forma_pagamento.descricao' },
    ],
    headersClassificacoes: [
      { text: 'Caracteristica', value: 'caracteristica' },
      { text: 'Valor', value: 'valor' },
    ],
  }
}
